<template>
	<Empty
		v-if="is_on_false"
		class="flex-column full-height bg-white position-relative"
	>
		<template
			v-slot:add
		>{{ error_msg }}</template>
	</Empty>
	<div
		v-else
		class="flex-column full-height bg-white position-relative"
	>

		<div class="full-height overflow-y-auto bg-gray-light pa-10">
			<ul class="">
				<li
					v-for="(item_withdrawal, w_index) in list_items"
					:key="'w_' + w_index"
					class="bg-white mb-10  shadow"
				>
					<div class="justify-space-between under-line pa-10">
						<h6>출금 정보 {{ w_index + 1 }}</h6>
						<div>
							<button v-if="item_withdrawal.is_holder" @click="item_withdrawal.is_holder = false"><v-icon>mdi-arrow-left-bold-circle</v-icon></button>
							<button v-if="w_index > 0" class="ml-10" @click="removeItem(w_index)"><v-icon>mdi-close-circle</v-icon></button>
						</div>
					</div>
					<div class="pa-10">
					<template
						v-if="item_withdrawal.is_holder"
					>
						<div class="flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2 ">
								<select
									v-model="item_withdrawal.bank_code"
									class=""
									disabled=""
									style="border: unset; color: black;"
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2">{{ item_withdrawal.bank_account }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2" :class="{ 'color-red': !item_withdrawal.item_holder.realBankOwnerName }">{{ item_withdrawal.item_holder.realBankOwnerName ? item_withdrawal.item_holder.realBankOwnerName : '출금계좌를 다시 확인하세요' }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2">{{ item_withdrawal.real_amount | makeComma }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2">{{ item_withdrawal.depositor }}</div>
						</div>
					</template>

					<template
						v-else
					>
						<div
							v-if="items_account.length > 0"
							class="flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold">최근출금계좌</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.account_uid"
									class="input-box "
									@change="setAccount(item_withdrawal)"
								>
									<option value="">선택하세요</option>
									<option
										v-for="(item, a_index) in list_account"
										:key="'account_' + a_index"
										:value="item.uid"
									>{{ item.bank_name }} {{ item.bank_holder }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank_code"
									class="input-box "
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_account" class="input-box " placeholder="출금 계좌를 입력하세요" maxlength="50" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_holder" class="input-box " placeholder="예금주를 입력하세요" maxlength="50" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">1회 출금한도</div>
							<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.payment_limit | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">최소 출금액</div>
							<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.minimum | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2 box">
								<div class="pa-10 justify-space-between ">
									<input v-model="item_withdrawal.amount" class=" " placeholder="금액을 입력하세요" maxlength="4" :rules="[$rules.max(item_withdrawal, 'amount', 8)]" type="number" @focus="setLast(item_withdrawal)"/>
									<div style="width: 40px">원</div>
								</div>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold"></div>
							<div class="flex-2 right color-blue justify-space-between">{{ item_withdrawal.txt_amount }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2"><input v-model="item_withdrawal.depositor" class="input-box " placeholder="입금자명을 입력하세요" maxlength="10" /></div>
						</div>
						<div
							v-if="item_withdrawal.is_holder"
							class="mt-10 flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold color-red">예금주 확인</div>
							<div class="flex-2 "><input class="input-box text-right" placeholder="입금자명을 입력하세요" maxlength="10" disabled :value="item_holder.realBankOwnerName" /></div>
						</div>
						<div class="mt-10"><button class="btn-primary btn" @click="postHolder(item_withdrawal)" :disabled="item_withdrawal.disabled">예금주 조회</button></div>
					</template>
					</div>
				</li>
			</ul>
			<div class="mt-10 text-right"><button class="" @click="appendItem"><v-icon class="color-plus">mdi-plus-circle</v-icon></button></div>
		</div>

		<div
			class="text-center"
		>
			<div class="pa-10">{{ total_amount | makeComma}} / <span class="font-weight-bold">{{ invalid_balance | makeComma }}원</span> </div>
			<div></div>
			<button
				class="btn btn-primary"
				:disabled="is_disabled"

				@click="onPin"
			>출금하기</button>
		</div>
	</div>
</template>
<script>
	import Empty from "../Layout/Empty";
	export default {
		name: 'UserMultiWithdrawal'
		,
		components: {Empty},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: '지갑 출금'
					, top: false
					, title: true
					, bottom: false
				}
				, items_withdrawal: []
				, shop_balance: ''
				, shop_uid: this.$route.params.shop_uid
				, items_account: []
				, shop_info: {}
				, max: 5
				, item_last: {}
				, is_on_false: false
				, error_msg: ''
			}
		}
		, computed: {

			list_account: function(){
				return this.items_account.filter( (item) => {
					this.user.bank_list.filter( (bank) => {
						if(item.bank_code == bank.bankId){
							item.bank_name = bank.bankName
						}
					})
					return item
				})
			}
			, total_amount: function(){
				let t = 0;
				this.items_withdrawal.filter( (item) => {
					t += Number(item.amount)
				})
				return t
			}
			, is_disabled: function (){
				let t = true
				this.items_withdrawal.filter( (item) => {
					t = false
					if(!item.is_holder || !item.item_holder.realBankOwnerName){
						t = true
					}
				})

				if(this.total_amount > this.invalid_balance){
					t = true
				}
				return t
			}
			, list_items: function(){
				return this.items_withdrawal.filter( (item) => {
					item.real_amount = Number(item.amount)
					if(item.amount){
						item.txt_amount = this.$common.geKoreanNumber((item.real_amount + '').replaceAll(',', ''))
					}else{
						item.txt_amount = ''
					}
					item.disabled = true
					if (item.bank_code && item.bank_account && item.bank_holder && item.amount && item.depositor) {
						// console.log(Number(this.real_amount) + this.total_fee)
						if (this.shop_balance >= Number(item.real_amount) + Number(this.shop_info.sales_fee_base)) {
							item.disabled = false
						}
					}
					return item
				})
			}
			, invalid_balance: function(){
				let t = this.shop_balance
				t -= (Number(this.shop_info.sales_fee_base) * this.items_withdrawal.length)
				return t
			}
		}
		, methods: {

			setAccount: function(item_withdrawal){
				console.log(item_withdrawal)
				this.items_account.filter( (item) => {
					if(item.uid == item_withdrawal.account_uid){
						item_withdrawal.bank_code = item.bank_code
						item_withdrawal.bank_account = item.bank_account
						item_withdrawal.bank_holder = item.bank_holder
					}
				})
			}
			, getData: async function(){
				try{
					this.$bus.$emit('on', true)
					let result = await this.$Axios({
						method: 'get'
						, url: 'user/getShopWithdrawalInfo'
						, data: {
							shop_uid: this.shop_uid
							, type: 'withdrawal'
						}
					})
					if(result.success){
						this.shop_balance = result.data.shop_balance
						this.items_account = result.data.account_list
						this.shop_info = result.data.shop_info
					}else{
						throw result.message
					}
				}catch(e){
					this.is_on_false = true
					this.error_msg = e
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, onPin: function(){
				this.$bus.$off('pinCallback')

				this.$bus.$emit('onPin', {
					type: 'check'
				})

				this.$bus.$on('pinCallback', (call) => {
					console.log('multiwithdrawal pinCallback', call)
					this.postMultiWithdrawal(call)
				})
			}
			, postMultiWithdrawal: async function(){
				this.$bus.$emit('on', true)

				try {

					const result = await this.$Axios({
						method: 'post'
						, url: 'user/postMultiWithdrawal'
						, data: {
							my_shop_uid: this.shop_uid
							, items: JSON.stringify(this.items_withdrawal)
						}
					})

					if (result.success) {
						this.$bus.$emit('to', { name: 'WalletList'})
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postHolder: async function(item_withdrawal){
				try{
					this.$bus.$emit('on', true)
					let result = await this.$Axios({
						method: 'post'
						, url: 'user/postHolder'
						, data: {
							shop_uid: item_withdrawal.shop_uid
							, amount: item_withdrawal.real_amount
							, bank_code: item_withdrawal.bank_code
							, bank_account: item_withdrawal.bank_account
							, bank_holder: item_withdrawal.bank_holder
							, depositor: item_withdrawal.depositor
							, holder: item_withdrawal.holder
						}
					})
					if(result.success){
						item_withdrawal.is_holder = true
						item_withdrawal.item_holder = result.data
						item_withdrawal.holder = item_withdrawal.item_holder.realBankOwnerName
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, appendItem: function(){
				try {

					if(this.items_withdrawal.length < this.max){

						this.items_withdrawal.push({
							shop_uid: this.shop_uid
							, amount: ''
							, bank_code: ''
							, bank_account: ''
							, bank_holder: ''
							, is_on_sms_confirm: false
							, is_sms_confirm: false
							, depositor: ''
							, account_uid: ''
							, is_holder: false
							, item_holder: {
								realBankOwnerName: ''
							}
						})

						if(this.invalid_balance < 0){
							this.items_withdrawal.pop()
							throw "출금가능금액을 확인하세요"
						}

					}else{
						throw `일괄이체는 최대 ${this.max}건까지 가능합니다`
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, removeItem: function(index){
				if(index > 0){
					this.items_withdrawal.splice(index, 1)
				}
			}
			, setLast: function(item){
				this.item_last = item
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
			this.appendItem()
		}
		, watch: {
			total_amount: {
				handler: function(call){
					if(call > Number(this.invalid_balance) || Number(this.invalid_balance) < 0){
						this.$set(this.item_last, 'amount', '')
						this.$bus.$emit('notify', { type: 'error', message: '출금가능금액을 확인하세요'})
					}
				}
			}
		}
	}
</script>